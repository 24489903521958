<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <h3 class="text-center">
          투자자 화면 미리보기 ({{ isFundaNow ? '펀다나우' : '일반대출' }})
        </h3>
      </v-col>

      <template v-if="overdueStatuses.length < 1">
        <v-col cols="12" sm="12" md="12">
          <h4 class="mt-5 text-center">
            연체상태를 추가하면 팝업이 나타납니다.
          </h4>
        </v-col>
      </template>

      <template v-else>
        <v-col cols="12" sm="12" md="12">
          <div class="custom-popup show">
            <div class="popup-contents pa-5 text-popup">
              <div class="d-flex justify-space-between align-center">
                <h3 class="ma-0 text-black">
                  <span class="text-black">
                    <template v-if="isFundaNow">
                      {{ fnStoreFundingData.fnStore.storeName }}
                    </template>
                    <template v-else>
                      {{ fundingData.title }}
                    </template>
                  </span>
                  연체 공지
                </h3>
                <a class="popup__close-btn cursor-pointer font-size--23"> X </a>
              </div>
              <div class="on-load">
                <v-row class="on-load__overdue-status mt-5" no-gutters>
                  <v-col cols="12" sm="12" md="12">
                    <label
                      for="overdue-select"
                      class="d-inline-block font-size--17"
                      >연체 상태:
                    </label>
                    <div
                      v-for="(status, index) in overdueStatuses"
                      :key="status.overdueStatus.name"
                      class="d-inline-block"
                    >
                      <template v-if="index > 0">
                        <span class="mx-1"> > </span>
                      </template>

                      <span
                        class="font-size--17"
                        :class="
                          overdueStatuses.length === index + 1
                            ? 'overdue-status__' + (status.overdueStatusIdx - 1)
                            : ''
                        "
                      >
                        {{ status.overdueStatus.name }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="on-load__notice mt-4" no-gutters>
                  <v-col cols="12" sm="12" md="12">
                    <v-row class="notice__summary mb-3" no-gutters>
                      <v-col cols="12" sm="12" md="12">
                        <label
                          class="
                            notice__sub-header
                            font-weight-bold
                            ma-0
                            mb-2
                            font-size--17
                          "
                        >
                          개요
                        </label>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <ul>
                          <li>
                            대출일: {{ fundingData.registerDate | dateFormat }}
                          </li>
                          <li>
                            연체발생일:
                            {{ overdueStatuses[0].createdTime | dateFormat }}
                          </li>
                          <li>연체사유: {{ overdueStatuses[0].reason }}</li>
                        </ul>
                      </v-col>
                    </v-row>

                    <v-row class="notice__summary mb-3" no-gutters>
                      <v-col cols="12" sm="12" md="12">
                        <label
                          class="
                            notice__sub-header
                            font-weight-bold
                            ma-0
                            mb-2
                            font-size--17
                          "
                        >
                          상환 계획
                        </label>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" class="mb-2">
                        <ul class="width-100 ma-0">
                          <li
                            v-for="(plan, index) in overdueRepaymentPlans"
                            :key="plan.orderIdx"
                            class="plan__list mb-1 py-1"
                          >
                            <span class="font-size--15" @click="openCal(index)">
                              {{ plan.createdTime | dateFormat }}
                            </span>
                            <v-text-field
                              v-show="false"
                              v-model="plan.createdTime"
                              label="이관일/발생일"
                              prepend-icon="event"
                              class="pa-0"
                              readonly
                              hide-details
                            ></v-text-field>
                            <v-row class="d-flex align-center" no-gutters>
                              <v-col cols="9" sm="9" md="9">
                                <select
                                  v-model="plan.plan"
                                  class="pa-2 cursor--pointer"
                                  style="width: 100%; border: 1px solid #8a94a2"
                                  @change="selectedOption(plan)"
                                >
                                  <option v-if="!!plan.plan" :value="plan.plan">
                                    {{ plan.plan }}
                                  </option>

                                  <option v-else value="" disabled selected>
                                    선택하세요
                                  </option>

                                  <option
                                    v-for="option in planSelector(plan.plan)"
                                    :key="option"
                                    :value="option"
                                  >
                                    {{ option }}
                                  </option>
                                </select>
                              </v-col>

                              <v-col
                                cols="3"
                                sm="3"
                                md="3"
                                class="cursor--pointer"
                              >
                                <v-row no-gutters>
                                  <v-col
                                    cols="6"
                                    sm="6"
                                    md="6"
                                    class="text-center"
                                    @click="removePlan(plan)"
                                  >
                                    <v-icon
                                      class="red--text"
                                      style="
                                        border: 1px solid red;
                                        border-radius: 100%;
                                      "
                                    >
                                      mdi-minus
                                    </v-icon>
                                  </v-col>

                                  <v-col
                                    cols="6"
                                    sm="6"
                                    md="6"
                                    class="text-center"
                                  >
                                    <v-icon>mdi-arrow-split-horizontal</v-icon>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <v-row v-show="plan.edit" no-gutters>
                              <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                  v-model="plan.plan"
                                  rows="2"
                                  class="mt-2"
                                  background-color="white"
                                  outlined
                                  hide-details
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </li>
                        </ul>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" class="pt-1">
                        <p
                          class="ma-0 cursor--pointer font-size--15"
                          @click="addPlan(overdueRepaymentPlans)"
                        >
                          <span class="green--text"> + </span>
                          상환계획 추가
                        </p>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" class="text-right">
                        <v-btn
                          :disabled="loading"
                          :loading="loading"
                          @click="savePlan()"
                        >
                          변경사항 저장
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row class="notice__summary mb-3" no-gutters>
                      <v-col cols="12" sm="12" md="12">
                        <label
                          class="
                            notice__sub-header
                            font-weight-bold
                            ma-0
                            mb-2
                            font-size--17
                          "
                        >
                          추심 현황
                        </label>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <ul>
                          <li
                            v-for="collectDetail in collectDetails"
                            :key="collectDetail.idx"
                            class="font-size--15 margin-b-5"
                          >
                            {{ collectDetail.entrustmentTime | dateFormat }}
                            <br />
                            {{ collectDetail.description }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>

                    <v-row class="notice__summary" no-gutters>
                      <v-col cols="12" sm="12" md="12">
                        <label
                          class="
                            notice__sub-header
                            font-weight-bold
                            ma-0
                            mb-2
                            font-size--17
                          "
                        >
                          참고 사항
                        </label>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <p
                          v-html="showMemoCreditor"
                          class="ma-0 mt-1 font-size--15"
                        ></p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
            <!-- popup-contents end -->
          </div>
          <!-- custom-popup end -->
        </v-col>
      </template>
    </v-row>

    <v-dialog ref="calendar" v-model="cal.show" persistent width="320px">
      <v-date-picker v-model="cal.value" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cal.show = false">Cancel</v-btn>
        <v-btn text color="primary" @click="datePick()">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    fundingData: {
      type: Object,
      default: null,
    },
    fnStoreFundingData: {
      type: Object,
      default: null,
    },
    isFundaNow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      planOptions: [
        '카드 매출 추가 적립을 통해 연체를 해소할 예정입니다.',
        '차주의 자력상환 (상환금 입금)을 통해 연체를 해소할 예정입니다.',
        '전문 추심기관에 채권 추심을 의뢰할 예정입니다.',
        '지급명령 접수를 통해 법적조치에 착수할 예정입니다.',
        '채권 매각을 결정하였으며, NPL사에 채권 매각을 의뢰합니다.',
        '직접 입력',
      ],
      overdueStatuses: [],
      overdueRepaymentPlans: [],
      collectDetails: [],
      cal: {
        show: false,
        selectedPlanIdx: null,
        value: null,
      },
    }
  },
  watch: {
    fnStoreFundingData: {
      deep: true,
      handler(v) {
        if (!!v) {
          this.fundingData = v.fundingData
          this.overdueStatuses = v.fundingDataOverdueStatuses
          this.overdueRepaymentPlans = v.fundingDataOverdueRepaymentPlans
          this.collectDetails = v.fundingDataCollectDetails
        }
      },
    },
  },
  computed: {
    showMemoCreditor() {
      if (!!!this.overdueStatuses[0].memoCreditor) {
        return
      }
      return this.overdueStatuses[0].memoCreditor.replace(
        /(?:\r\n|\r|\n)/g,
        '<br />'
      )
    },
    planSelector() {
      return (selected) => {
        return this.planOptions.filter((plan) => {
          return plan !== selected
        })
      }
    },
  },
  methods: {
    addPlan(plans) {
      let len = plans.length
      let newObj = {
        fundingDataIdx: this.fundingData.idx,
        orderIdx: len < 1 ? 1 : plans[len - 1].orderIdx + 1,
        plan: '',
        createdTime: new Date().toISOString().substr(0, 10),
      }

      if (this.isFundaNow === true) {
        newObj.fnStoreFundingDataIdx = this.fnStoreFundingData.idx
      } else {
        if (len < 1) {
          this.$swal.basic.error('연체 상태를 먼저 저장하고 진행해 주세요.')
          return
        }

        newObj.fundingDataOverdueStatusIdx =
          plans[len - 1].fundingDataOverdueStatusIdx
      }

      plans.push(newObj)
    },
    openCal(index) {
      this.cal.show = true
      this.cal.selectedPlanIdx = index
      this.cal.value = this.overdueRepaymentPlans[index].createdTime.substr(
        0,
        10
      )
    },
    datePick() {
      this.$refs.calendar.save(this.cal.value)
      this.overdueRepaymentPlans[this.cal.selectedPlanIdx].createdTime =
        this.cal.value
    },
    selectedOption(v) {
      if (!!!v.edit) {
        v.edit = false
      }

      if (v.plan === '직접 입력') {
        v.edit = true
      } else {
        v.edit = false
      }
    },
    async savePlan() {
      let plans = this.isFundaNow ? this.overdueRepaymentPlans : []
      let params = plans.reduce((arr, d) => {
        let param = { ...d }
        delete param.edit
        arr.push(param)
        return arr
      }, [])

      if (params.length < 1) {
        this.$swal.basic.warning('변경할 내용이 없습니다.')
        return
      }

      let yes = await this.$swal.basic.confirm('상환 계획을 저장합니까??')

      if (yes.isConfirmed === false) {
        return
      }

      this.loading = true

      let q = `
          data: modifyRepaymentPlans(inputRepaymentPlans: $input) {
            idx
            orderIdx
            fundingDataIdx
            fnStoreFundingDataIdx
            fundingDataOverdueStatusIdx
            plan
            createdTime
          }
        `

      try {
        let data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: [InputFundingDataOverdueRepaymentPlan]!)',
          { input: params }
        )

        if (this.isFundaNow) {
          this.fnStoreFundingData.fundingDataOverdueRepaymentPlans = data
        } else {
        }

        this.$swal.basic.success('연체상태가 업데이트되었습니다.')
      } catch (e) {
        console.log(e)
        this.$swal.basic.error('에러 ㅠㅠㅠ')
      } finally {
        this.loading = false
      }
    },
    async removePlan(plan) {
      if (!!!plan.idx) {
        return
      }

      let yes = await this.$swal.basic.confirm('선택 항목을 제거합니다??')

      if (yes.isConfirmed === false) {
        return
      }

      this.loading = true

      let q = `
          data: removeRepaymentPlan(repaymentPlanIdx: ${plan.idx}) {
            idx
            orderIdx
            fundingDataIdx
            fnStoreFundingDataIdx
            fundingDataOverdueStatusIdx
            plan 
            createdTime
          }
        `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (this.isFundaNow) {
          this.fnStoreFundingData.fundingDataOverdueRepaymentPlans = data
        } else {
        }

        this.$swal.basic.success('연체상태가 업데이트되었습니다.')
      } catch (e) {
        console.log(e)
        this.$swal.basic.error('에러 ㅠㅠㅠ')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
